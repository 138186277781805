<template>
<div>
  <div class="base" v-if="dados.length <= 0">
    <div class="fundo">
      <div class="wave"></div>
    </div>
    <div class="grid-base">
      <div class="text-rastreamento" v-if="!isMobile">
        <p class="text1">
          <svg
            class="mr-2"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 14V1H1V14H16ZM16 14H23V9L20 6H16V14ZM8 16.5C8 17.8807 6.88071 19 5.5 19C4.11929 19 3 17.8807 3 16.5C3 15.1193 4.11929 14 5.5 14C6.88071 14 8 15.1193 8 16.5ZM21 16.5C21 17.8807 19.8807 19 18.5 19C17.1193 19 16 17.8807 16 16.5C16 15.1193 17.1193 14 18.5 14C19.8807 14 21 15.1193 21 16.5Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Greeen Envios
        </p>
        <p class="text2">Acompanhe a entrega do seu pedido 📦</p>
        <div class="grid-box">
          <div class="box1"></div>
          <div class="box2">
            <svg
              width="25"
              height="21"
              viewBox="0 0 25 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.3887 2L22.9998 10.6111L14.3887 19.2222"
                stroke="#2133D2"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M2 2.00003L10.6111 10.6111L2 19.2223"
                stroke="#2133D2"
                stroke-opacity="0.3"
                stroke-width="3"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
      </div>
      <div v-if="isMobile" class="space-mobile">
        <p class="text1">
          <svg
            class="mr-2"
            width="24"
            height="20"
            viewBox="0 0 24 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M16 14V1H1V14H16ZM16 14H23V9L20 6H16V14ZM8 16.5C8 17.8807 6.88071 19 5.5 19C4.11929 19 3 17.8807 3 16.5C3 15.1193 4.11929 14 5.5 14C6.88071 14 8 15.1193 8 16.5ZM21 16.5C21 17.8807 19.8807 19 18.5 19C17.1193 19 16 17.8807 16 16.5C16 15.1193 17.1193 14 18.5 14C19.8807 14 21 15.1193 21 16.5Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          Greeen Envios
        </p>
        <p class="text1">Acompanhe a entrega do seu pedido 📦</p>
      </div>
      <div class="form-rastreamento" data-anima="top">
        <b-form @submit.prevent="onSubmit" novalidate>
          <b-form-group label="Código do Pedido" label-for="rastreamento">
            <b-form-input
              id="rastreamento"
              placeholder="Ex: AA12356785BR"
              v-model="codigo"
              type="text"
              name="rastreamento"
              v-validate="'required'"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('rastreamento')">
              Por favor, insira seu código.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="CPF/CNPJ utilizado na compra" label-for="cpf">
            <b-form-input
              id="cpf"
              placeholder="Ex: 411.321.588/00"
              v-model="cpf"
              type="text"
              v-mask="['###.###.###-##', '##.###.###/####-##']"
              name="cpf"
              v-validate="'required'"
            ></b-form-input>
            <b-form-invalid-feedback :state="!errors.has('cpf')">
              Por favor, insira seu cpf.
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-invalid-feedback :state="success">
            O Código ou CPF estão incorretos.
          </b-form-invalid-feedback>

          <b-row>
            <GridColum :tamanho="12" class="not-padding">
              <b-overlay
                :show="loading"
                rounded
                opacity="0"
                spinner-small
                spinner-variant="primary"
                class="btn-acoes"
              >
                <GridColum
                  :tamanho="12"
                  :mt="3"
                  class="d-flex justify-content-between btns-mobile"
                >
                  <BaseButton @click="onSubmit" variant="primary"
                    >Acompanhar pedido</BaseButton
                  >
                </GridColum>
              </b-overlay>
            </GridColum>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
  <div v-else data-anima="right" class="container mt-4 mb-4">
    <div @click="reset" class="pointer mb-4">
      <svg width="25" height="21" viewBox="0 0 25 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.6113 19.2223L2.00022 10.6111L10.6113 2.00004" stroke="#2133D2" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M23 19.2222L14.3889 10.6111L23 2.00001" stroke="#2133D2" stroke-opacity="0.3" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div :class="{'gridPedidos':!isMobile, 'gridPedidosMobile':isMobile}" v-if="!loading">
      <Menu :dados="dados" :isMobile="isMobile" :removeActions="true" />
      <Detalhes :dados="dados" :isMobile="isMobile" />
    </div>
  </div>
</div>
</template>

<script>
import GridColum from "@/components/base/GridColum.vue";
import Menu from "@/components/Menu.vue";
import Detalhes from "@/components/Detalhes.vue";

import ClienteService from "@/services/resources/ClienteService";
const service = ClienteService.build();

export default {
  name: "Login",
  components: {
    GridColum,
    Menu,
    Detalhes
  },
  data() {
    return {
      client: {
        width: 0,
      },
      loading: false,
      success: true,
      codigo: "",
      cpf: "",
      dados:[]
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    reset(){
      this.dados = [];
      this.codigo = "";
      this.cpf = "";
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    onSubmit() {
      this.submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.success = true;
          this.loading = true;

          let data = {
            id: 'tracking',
            client_document: this.cpf,
            shipping_code: this.codigo
          }

          service
            .postID(data)
            .then((e) => {
              console.log(e);
              this.dados = e;
            })
            .catch((err) => {
              console.log(err);
              this.$bvToast.toast("Dados Invalidos!", {
                title: "Rastreamento!",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.space-mobile {
  padding: 0 60px;
}

.base {
  background: #2133d2;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.btns-mobile {
  flex-direction: column-reverse;
  padding: unset;
}

.text-rastreamento {
  width: 90%;
  padding: 45px 40px 50px 40px;
  margin: 15px;
}

.form-rastreamento {
  width: 70%;
  background: #fff;
  padding: 45px 40px 50px 40px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  z-index: 2;
  max-height: 395px;
}

.text1 {
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}

.text2 {
  font-weight: bold;
  font-size: 36px;
  color: #ffffff;
  line-height: 150% !important;
  max-width: 18em;
}

.fundo {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .wave {
    margin: 0;
    width: 40%;
    height: 80%;
    background: linear-gradient(
      215.84deg,
      rgba(255, 255, 255, 0.3) 8.34%,
      rgba(255, 255, 255, 0) 75.73%
    );
    border-radius: 20px;
    transform: matrix(0.71, 0.41, -1.22, 0.71, 0, 0);
  }
}

.grid-base {
  display: grid;
  grid-template-columns: 1fr 1fr;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.grid-box {
  display: flex;
  gap: 22px;
  .box1 {
    width: 64.77px;
    height: 64.77px;
    background: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0.3) -10.29%,
      rgba(255, 255, 255, 0.19543) 23.09%,
      rgba(255, 255, 255, 0) 82.38%
    );
    border-radius: 5px;
  }
  .box2 {
    width: 64.77px;
    height: 64.77px;
    background: #ffffff;
    box-shadow: 10px 10px 20px rgba(37, 38, 49, 0.1);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 675px) {
  .grid-base {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    justify-items: center;
  }
}
</style>
